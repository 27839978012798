import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { format } from 'date-fns';

import { Button, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Label, { LabelProps } from '~/components/Label';
import Tooltip from '~/components/Tooltip';

import { Container, Content, DisableText, Header, LabelContainer, Title } from './design';

import { HRIS_REPORT_STATUS } from '~/constants/hrisIntegrations';
import { COLORS } from '~/styles';

const SEND_REPORT_STATUS: Record<HRIS_REPORT_STATUS, LabelProps> = {
  FAILED: {
    icon: ICONS.ERROR_SIGN,
    noBorder: true,
    children: <Trans>Failed</Trans>,
    color: COLORS.ACCENT_ERROR,
  },
  IN_PROGRESS: {
    icon: ICONS.IN_PROGRESS,
    noBorder: true,
    children: <Trans>In progress</Trans>,
    color: COLORS.STATUS_IN_PROGRESS,
  },
  NOT_SENT: {
    icon: ICONS.TODO,
    noBorder: true,
    children: <Trans>Not Sent</Trans>,
    color: COLORS.STATUS_ACTIVE,
  },
  [HRIS_REPORT_STATUS.SENT]: {
    icon: ICONS.CHECKMARK,
    noBorder: true,
    children: <Trans>Sent</Trans>,
    color: COLORS.STATUS_COMPLETED,
  },
};

export type HRISSendReportProps = {
  name: string;
  status: HRIS_REPORT_STATUS;
  sentOn?: Date;
  onSendReport?(): void;
  enable?: boolean;
  errorMessage?: string;
};

export const HRISSendReport = ({
  name,
  status,
  sentOn,
  enable = false,
  errorMessage,
}: HRISSendReportProps) => {
  const { i18n } = useLingui();

  const statusTooltip =
    status === HRIS_REPORT_STATUS.SENT && sentOn
      ? i18n._(t`Sent on: ${format(sentOn, 'dd-MM-yyyy')}`)
      : status === HRIS_REPORT_STATUS.FAILED && errorMessage;

  return (
    <Container>
      <Header>
        <Tooltip tooltip={name}>
          <Title>{i18n._(t`Send report to ${name}`)}</Title>
        </Tooltip>
        {enable && (
          <Tooltip tooltip={statusTooltip}>
            <LabelContainer>
              <Label {...SEND_REPORT_STATUS[status]}>{SEND_REPORT_STATUS[status].children} </Label>
            </LabelContainer>
          </Tooltip>
        )}
        {!enable && (
          <LabelContainer>
            <DisableText>
              <Trans>Only for completed or archived review</Trans>
            </DisableText>
          </LabelContainer>
        )}
      </Header>
      <Content>
        <Tooltip
          tooltip={
            !enable && i18n._(t`The report can be sent when the review is completed or archived`)
          }
        >
          <div>
            <Button
              variant={ButtonVariant.SECONDARY}
              label={i18n._(t`Send report`)}
              icon={ICONS.SEND}
              disabled={!enable || status === HRIS_REPORT_STATUS.IN_PROGRESS}
            />
          </div>
        </Tooltip>
      </Content>
    </Container>
  );
};
