import React from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { HRIS_REPORT_STATUS } from '~/constants/hrisIntegrations';

import { Block } from '../components/Block';
import { HRISSendReport, HRISSendReportProps } from '../components/HRISSendReport';
import { RowWrapper, Subtitle } from '../design';

// TODO: (MOCK DATA) This is going to be removed once API is done
// Start Mock Data
const IntegrationsMock: HRISSendReportProps[] = [
  {
    name: 'Visma Raet - Youforce',
    status: HRIS_REPORT_STATUS.SENT,
    onSendReport: () => {
      alert('Sent');
    },
    errorMessage: 'API Error, integration no longer activated',
    sentOn: new Date(),
  },
  {
    name: 'Kenjo',
    status: HRIS_REPORT_STATUS.NOT_SENT,
    onSendReport: () => {
      alert('Not Sent');
    },
    errorMessage: 'API Error, integration no longer activated',
    sentOn: new Date(),
  },
  {
    name: 'Microsoft Entra ID',
    status: HRIS_REPORT_STATUS.IN_PROGRESS,
    errorMessage: 'API Error, integration no longer activated',
    sentOn: new Date(),
  },
  {
    name: 'HRworks',
    status: HRIS_REPORT_STATUS.FAILED,
    onSendReport: () => {
      alert('Sent');
    },
    errorMessage: 'API Error, integration no longer activated',
    sentOn: new Date(),
  },
];

// End Mock Data

type SendReportProps = {
  status: USER_REVIEW_STATUS;
};

const SendReport = ({ status }: SendReportProps) => {
  const { i18n } = useLingui();

  return (
    <Block title={i18n._(t`Send report`)}>
      <RowWrapper $margin={'0 0 24px 0'}>
        <Subtitle>
          <Trans>A PDF of this review report is added to the employee personnel file.</Trans>
        </Subtitle>
      </RowWrapper>

      {IntegrationsMock.map((data, index) => (
        <RowWrapper key={index} $margin={'0 36px 24px 0 '}>
          <HRISSendReport
            {...data}
            enable={[USER_REVIEW_STATUS.COMPLETED, USER_REVIEW_STATUS.ARCHIVED].includes(status)}
          />
        </RowWrapper>
      ))}
    </Block>
  );
};

export { SendReport };
